import axios from '../../anaxios';
import settings from '../../settings';
import { BOOKING_FETCH_SUCCEEDED } from '../bookings/action-types';

import {
  CLIENT_IMPORT_REQUESTED,
  CLIENT_IMPORT_SUCCEEDED,
  CLIENT_IMPORT_FAILED,
  CLIENT_FETCH_REQUESTED,
  CLIENT_FETCH_SUCCEEDED,
  CLIENT_FETCH_FAILED,
  CLIENT_SAVE_REQUESTED,
  CLIENT_SAVE_SUCCEEDED,
  CLIENT_SAVE_FAILED,
  CLIENT_DELETE_REQUESTED,
  CLIENT_DELETE_SUCCEEDED,
  CLIENT_DELETE_FAILED,
  CLIENTS_DELETE_REQUESTED,
  CLIENTS_DELETE_SUCCEEDED,
  CLIENTS_DELETE_FAILED,
} from './action-types';

export const fetchClient = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENT_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/clients/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: CLIENT_FETCH_SUCCEEDED,
          payload: { [data.id]: data },
        });
        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_FETCH_FAILED });
        return Promise.reject(error);
      });
  };
};

export const saveClient = (client) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENT_SAVE_REQUESTED });

    return axios
      .put(`${settings.api2Root}/clients/${client.id}`, client, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({ type: CLIENT_SAVE_SUCCEEDED, payload: data });
        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_SAVE_FAILED });
        return Promise.reject(error);
      });
  };
};

export const fetchClients = ({
  sortOrder,
  sortKey,
  businessId,
  locationId,
  bulkMessageId,
  search,
  offset,
  limit,
  selector,
  includeDeleted,
  clientIds,
}: {
  sortOrder?: string;
  sortKey?: string;
  businessId?: string;
  locationId?: string;
  bulkMessageId?: string;
  search?: string;
  offset?: number;
  limit?: number;
  selector?: Record<string, any>;
  includeDeleted?: boolean;
  clientIds?: string[];
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;
    // const businessId = state.userPreferences.currentBusinessId;

    dispatch({ type: CLIENT_FETCH_REQUESTED });

    return axios
      .get(`${settings.api2Root}/clients`, {
        params: {
          // business_id: businessId,
          bulk_message_id: bulkMessageId,
          sort_order: sortOrder,
          sort_key: sortKey,
          business_id: businessId,
          location_id: locationId,
          search: search || '',
          offset,
          limit,
          selector,
          include_deleted: includeDeleted,
          client_ids: clientIds,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        const clients = data.data.reduce((acc, client) => {
          acc[client.id] = client;
          return acc;
        }, {});

        dispatch({
          type: CLIENT_FETCH_SUCCEEDED,
          payload: clients,
          meta: {
            serverTotal: data.meta.unfiltered_total,
          },
        });

        if (data.bookings) {
          dispatch({
            type: BOOKING_FETCH_SUCCEEDED,
            payload: data.bookings.reduce((acc, booking) => {
              acc[booking.id] = booking;
              return acc;
            }, {}),
          });
        }

        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_FETCH_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const importClients = (
  clients,
  locationIds = [],
  hasPriorBookings = false
) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENT_IMPORT_REQUESTED });

    return axios
      .post(
        `${settings.api2Root}/clients-import`,
        {
          clients,
          business_id: state.userPreferences.currentBusinessId,
          location_ids: locationIds,
          has_prior_bookings: hasPriorBookings,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        // const clients = data.reduce((acc, client) => {
        //     acc[client.id] = client;
        //     return acc;
        // }, {});
        // dispatch({type: CLIENT_IMPORT_SUCCEEDED, payload: clients});
        dispatch({ type: CLIENT_IMPORT_SUCCEEDED, payload: [] });

        // Fetch some new clients
        dispatch(
          fetchClients({
            businessId: state.userPreferences.currentBusinessId,
            limit: 20,
          })
        );

        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_IMPORT_FAILED });
        return Promise.reject(error);
      });
  };
};

export const createClient = (client) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENT_SAVE_REQUESTED });

    return axios
      .post(`${settings.api2Root}/clients`, client, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        (client.merge_ids || []).forEach((id) => {
          dispatch({ type: CLIENT_DELETE_SUCCEEDED, payload: id });
        });

        dispatch({ type: CLIENT_SAVE_SUCCEEDED, payload: data });
        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_SAVE_FAILED });
        return Promise.reject(error);
      });
  };
};

export const deleteClient = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENT_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/clients/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({ type: CLIENT_DELETE_SUCCEEDED, payload: id });
        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENT_DELETE_FAILED });
        return Promise.reject(error);
      });
  };
};

export const deleteClients = ({
  ids = [],
  from = null,
  until = null,
  createdBy = null,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = state.session.token;

    dispatch({ type: CLIENTS_DELETE_REQUESTED });

    return axios
      .delete(`${settings.api2Root}/clients`, {
        data: {
          ids,
          from,
          until,
          created_by: createdBy,
          business_id: state.userPreferences.currentBusinessId,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({ type: CLIENTS_DELETE_SUCCEEDED, payload: ids });
        return data;
      })
      .catch((error) => {
        dispatch({ type: CLIENTS_DELETE_FAILED });
        return Promise.reject(error);
      });
  };
};
